import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-95cb6d68"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "el-dropdown-link"
};
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'avatar',

  setup(__props) {
    const store = useStore(); // const squareUrl = ref('https://www.ipolarx.com/api/getLogoIcon')

    const userInfo = ref();

    const logout = () => {
      store.dispatch('app/logout');
    };

    onMounted(() => {
      userInfo.value = JSON.parse(localStorage.getItem('userInfo'));
      console.log(userInfo.value); // const userInfo = localStorage.getItem('userInfo')
    });
    return (_ctx, _cache) => {
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

      const _component_el_dropdown = _resolveComponent("el-dropdown");

      return _openBlock(), _createBlock(_component_el_dropdown, null, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            onClick: logout
          }, {
            default: _withCtx(() => [_createTextVNode("退出")]),
            _: 1
          })]),
          _: 1
        })]),
        default: _withCtx(() => {
          var _userInfo$value;

          return [_createElementVNode("span", _hoisted_1, _toDisplayString((_userInfo$value = userInfo.value) === null || _userInfo$value === void 0 ? void 0 : _userInfo$value.account), 1)];
        }),
        _: 1
      });
    };
  }

};