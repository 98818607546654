import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-1f09f90c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  key: 0,
  class: "no-redirect"
};
const _hoisted_2 = ["onClick"];
import { watch, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
export default {
  __name: 'breadcrumb',

  setup(__props) {
    const route = useRoute();
    const router = useRouter();
    const breadcrumbList = ref([]);
    const authName = ref([]);

    const initBreadcrumbList = () => {
      breadcrumbList.value = [route.matched[1]];
      const name = breadcrumbList.value[0].name;
      console.log(name);
      const menuList = JSON.parse(localStorage.getItem('menuList'));
      menuList.map(item => {
        if (item.children) {
          item.children.map(item1 => {
            if (name !== 'homepage') {
              if (name === item1.path) {
                authName.value = item.authName;
              }
            } else {
              authName.value = null;
            }
          });
        }
      });
    };

    const handleRedirect = path => {
      sessionStorage.setItem('path', '/orders');
      router.push(path);
    };

    watch(route, () => {
      initBreadcrumbList();
    }, {
      deep: true,
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");

      const _component_el_breadcrumb = _resolveComponent("el-breadcrumb");

      return _openBlock(), _createBlock(_component_el_breadcrumb, {
        separator: "/"
      }, {
        default: _withCtx(() => [authName.value ? (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
          key: 0
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(authName.value), 1)]),
          _: 1
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(breadcrumbList.value, (item, index) => {
          return _openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: index
          }, {
            default: _withCtx(() => [index === breadcrumbList.value.length - 1 ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(`menus.${item.name}`)), 1)) : (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "redirect",
              onClick: $event => handleRedirect(item.path)
            }, _toDisplayString(_ctx.$t(`menus.${item.name}`)), 9, _hoisted_2))]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      });
    };
  }

};