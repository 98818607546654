import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'lang',

  setup(__props) {
    const i18n = useI18n();
    const store = useStore();
    const currentLanguage = computed(() => {
      return i18n.locale.value;
    });

    const handleCommand = val => {
      i18n.locale.value = val;
      store.commit('app/changLang', val);
      localStorage.setItem('lang', val);
    };

    return (_ctx, _cache) => {
      const _component_svg_icon = _resolveComponent("svg-icon");

      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");

      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");

      const _component_el_dropdown = _resolveComponent("el-dropdown");

      return _openBlock(), _createBlock(_component_el_dropdown, {
        onCommand: handleCommand
      }, {
        dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
          default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
            command: "zh",
            disabled: currentLanguage.value === 'zh'
          }, {
            default: _withCtx(() => [_createTextVNode("中文")]),
            _: 1
          }, 8, ["disabled"]), _createVNode(_component_el_dropdown_item, {
            command: "en",
            disabled: currentLanguage.value === 'en'
          }, {
            default: _withCtx(() => [_createTextVNode("English")]),
            _: 1
          }, 8, ["disabled"])]),
          _: 1
        })]),
        default: _withCtx(() => [_createVNode(_component_svg_icon, {
          icon: "language"
        })]),
        _: 1
      });
    };
  }

};